import React, {useEffect, useState} from 'react';
import './App.css';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {routes as appRoutes} from "./routes/Routes";
import Layout from "./components/layout/Layout";
import "@fontsource/open-sans/index.css"
import {AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate} from "@azure/msal-react";
import Login from "./pages/Login";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {useDefaultTheme} from "themeConfig";
import {FeatureSwitchContext, FeatureSwitchProps} from "utils/FeatureSwitchContext";
import {getMsalInstance} from "./authConfig";
import {IPublicClientApplication} from "@azure/msal-browser";

// TODO replace css with styled components - https://jira.scout24.com/browse/R2UFC-416
function App() {
    const [msalInstance, setMsalInstance] = useState<IPublicClientApplication | null>(null);
    const theme = useDefaultTheme();

    const routeList = appRoutes
        .map(route => [route, ...(route.subRoutes || []), ...(route.subRoutesInContent || [])])
        .flat();

    const featureSwitchProps: FeatureSwitchProps = {
        isEditSelfPauseDisabled: false,
        isEditScheduledPauseDisabled: false,
        isEditPausedByCustomerCareDisabled: false,
        isReportsContentDisabled: process.env.REACT_APP_ENV === 'pro'
    }

    useEffect(() => {
        const initializeMsal = async () => {
            const instance = await getMsalInstance();
            setMsalInstance(instance);
        };
        initializeMsal();
    }, []);

    return msalInstance && (
        <MsalProvider instance={msalInstance}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <CssBaseline/>
                    <BrowserRouter>
                        <Layout>
                            <UnauthenticatedTemplate>
                                <Login/>
                            </UnauthenticatedTemplate>
                            <AuthenticatedTemplate>
                                <FeatureSwitchContext.Provider value={featureSwitchProps}>
                                    <Routes>
                                        {routeList.map((route) => (
                                            <Route
                                                key={route.key}
                                                path={route.path}
                                                element={<route.component/>}
                                            />
                                        ))}
                                    </Routes>
                                </FeatureSwitchContext.Provider>
                            </AuthenticatedTemplate>
                        </Layout>
                    </BrowserRouter>
                </LocalizationProvider>
            </ThemeProvider>
        </MsalProvider>
    );
}

export default App;
