import React, {useContext, useEffect, useState} from "react";
import {Box, CircularProgress} from "@mui/material";
import UmzugEasyTable from "components/table/UmzugEasyTable";
import {usePartnerLoader} from "components/table/schema/type/PartnerSchema";
import {useTranslation} from "react-i18next";
import {TextField} from "components/form/TextField";
import FormLabel from "components/form/FormLabel";
import Button from "components/form/Button";
import SearchIcon from "@mui/icons-material/Search";
import ToggleSwitch from "components/form/ToggleSwitch";
import {FormikProps, useFormik} from "formik";
import UmzugEasyTableFooter from "components/table/UmzugEasyTableFooter";
import MultipleRowSkeleton from "components/feedback/MultipleRowSkeleton";
import {loadNextPageIfPossible} from "components/table/util/UmzugEasyTableFunctions";

const OverviewContext = React.createContext<FormikProps<OverviewFormValues> | undefined>(undefined);

type OverviewFormValues = {
    partnerName: string,
    validPartnersOnly: boolean
}

const useOverviewFormik = (onSubmit?: (values: OverviewFormValues) => void): FormikProps<OverviewFormValues> | undefined => {
    const [overviewForm, setOverviewForm] = useState<FormikProps<OverviewFormValues> | undefined>(undefined)
    const storedPartnerName = sessionStorage.getItem("partnerName") || '';
    const formik: FormikProps<OverviewFormValues> = useFormik<OverviewFormValues>({
        initialValues: {
            partnerName: storedPartnerName,
            validPartnersOnly: true
        },
        onSubmit: (values) => {
            if (onSubmit != null) {
                onSubmit(values);
            }
            sessionStorage.setItem("partnerName", values.partnerName);
        },
    });
    useEffect(() => {
        setOverviewForm(formik)
        // eslint-disable-next-line
    }, [])

    return overviewForm;
}

type SearchPartnerProps = {
    loading?: boolean
}

const SearchPartner = ({loading}: SearchPartnerProps) => {
    const {t} = useTranslation('partner');
    const formik = useContext(OverviewContext);
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column"
            }}>
            <FormLabel customStyles={{ textTransform: 'none' }} title={t('list.search.findPartners')}/>
            <Box
                sx={{
                    display: "flex"
                }}>
                <TextField id="partnerName" label={t('list.search.searchTerm')}
                           sx={{width: '50vw', maxWidth: '770px'}}
                           defaultValue={formik?.initialValues.partnerName}
                           onChange={formik?.handleChange}/>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "end",
                    paddingRight: "20px"
                }}>
                <span style={{
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                    marginRight: "20px"
                }}>
                    <FormLabel
                        title={t('list.search.validPartnersOnly')}
                        fontSize="12px"
                        fontWeight={400}
                        customStyles={{textTransform: 'none'}}
                    />
                </span>
                <ToggleSwitch
                    disabled={loading === true}
                    id="validPartnersOnly"
                    defaultChecked={formik?.initialValues.validPartnersOnly}
                    onChange={formik?.handleChange}/>
            </Box>
        </Box>
    )
}

type SearchBarProps = {
    loading?: boolean
}

const SearchBar = ({loading}: SearchBarProps) => {
    const formik = useContext(OverviewContext);
    const {t} = useTranslation('partner');
    return (
        <form onSubmit={formik?.handleSubmit}>
            <Box
                sx={{
                    display: "flex",
                    padding: "1rem",
                    justifyContent: "center",
                    alignItems: "end"
                }}>
                <SearchPartner loading={loading}/>
                <Box sx={{paddingBottom: "34px", paddingLeft: "40px"}}>
                    <Button
                        type={'submit'}
                        disabled={loading}
                        icon={loading ? <CircularProgress size={20} sx={{ mr: '10px' }} color='inherit' /> : <SearchIcon sx={{fontSize: '18px', marginRight: "10px"}}/>}
                        label={t('list.search.search')}/>
                </Box>
            </Box>
        </form>
    )
}

const Partners: React.FC = () => {
    const {tableProps, count, pageCount, page, filters, loadNextPage, changeQuery, loading} = usePartnerLoader(false);

    const handleOnSubmit = (values: OverviewFormValues) =>
        changeQuery({listQuery: values.partnerName, validPartnersOnly: values.validPartnersOnly});

    const overviewForm = useOverviewFormik(handleOnSubmit);

    useEffect(() => {
        overviewForm?.submitForm()
    }, [overviewForm])

    return (
        <OverviewContext.Provider value={overviewForm}>
            <Box
                sx={{
                    flexGrow: 1,
                    backgroundColor: "whitesmoke",
                    display: "block",
                    padding: "1rem 1rem 0 1rem",
                    overflowY: "auto"
                }}
            >
                {overviewForm && <SearchBar loading={loading}/>}
                {page > -1 ?
                    <>
                        <Box sx={{ height: 'calc(100vh - 280px)', overflowY: 'auto' }}>
                            <UmzugEasyTable
                                {...tableProps}
                                onLastRowRendered={() => loadNextPageIfPossible(page, pageCount, loadNextPage)}
                            />
                        </Box>
                        <UmzugEasyTableFooter
                            count={count} search={filters?.listQuery}
                            translateFile="partner"
                            translation={{withoutSearch: 'list.partnerFound', withSearch: 'list.filteredPartnerFound'}}
                        />
                    </>
                    :
                    <MultipleRowSkeleton rows={15} fontSize='30px' />
                }
            </Box>
        </OverviewContext.Provider>
    );
}

export default Partners
