import React, {FC, ReactElement, useContext, useEffect, useState} from "react";
import {Box, CircularProgress, Theme} from "@mui/material";
import UmzugEasyTable from "../components/table/UmzugEasyTable";
import {RelocationRequest} from "../api/relocationRequest/RelocationRequest";
import {useRelocationRequestsLoader} from "../components/table/schema/type/RelocationRequestsSchema";
import UmzugEasyTableFooter from "../components/table/UmzugEasyTableFooter";
import {useTranslation} from "react-i18next";
import {TextField} from "../components/form/TextField";
import Button from "../components/form/Button";
import SearchIcon from "@mui/icons-material/Search";
import {makeStyles, useTheme} from "@mui/styles";
import ActionImg from "../components/shared/ActionImg";
import {RelocationRequestFilters} from "../api/relocationRequest/RelocationRequestFilters";
import {FormikProps, useFormik} from "formik";
import RelocationRequestDetail from "./relocationRequest/relocationRequestDetail/RelocationRequestDetail";
import MultipleRowSkeleton from "../components/feedback/MultipleRowSkeleton";
import {loadNextPageIfPossible} from "../components/table/util/UmzugEasyTableFunctions";
import {
    RelocationRequestDetail as RelocationRequestDetailResponse
} from "../api/relocationRequest/RelocationRequestDetail"
import {Lead} from "../api/lead/LeadResources";
import {DeletionConfirmationModalProps} from "../components/form/DeletionConfirmationModal";
import {AlertProps} from "../components/feedback/Alert";

export const RelocationRequestContext = React.createContext<RelocationRequestContextValues>({
    partialTable: false,
    setPartialTable: (value: boolean) => {},
    count: 0,
    form: undefined,
    alert: undefined,
    relocationRequestDetail: undefined,
    relocationRequestDetailLoading: false,
    onAnonymizeRelocationRequest: () => {},
    anonymizationConfirmationModal: undefined,
    isAnonymizingRelocationRequest: false,
    leads: [],
});
export const useRelocationRequestContext = () => useContext(RelocationRequestContext)

type RelocationRequestContextValues = {
    partialTable: boolean
    setPartialTable: (value: boolean) => void
    count: number
    form: FormikProps<RelocationRequestFilters> | undefined
    alert: AlertProps | undefined
    relocationRequestDetail: RelocationRequestDetailResponse | undefined
    relocationRequestDetailLoading: boolean
    onAnonymizeRelocationRequest: () => void
    anonymizationConfirmationModal: DeletionConfirmationModalProps | undefined
    isAnonymizingRelocationRequest: boolean
    leads: Lead[]
}

const useRelocationRequestFormik = (onSubmit: (value: RelocationRequestFilters) => void): FormikProps<RelocationRequestFilters> | undefined => {
    const [relocationRequestForm, setRelocationRequestForm] = useState<FormikProps<RelocationRequestFilters> | undefined>(undefined)
    const formik: FormikProps<RelocationRequestFilters> = useFormik<RelocationRequestFilters>({
        initialValues: {
            requestId: '',
            listQuery: ''
        },
        onSubmit: (values) => {
            if (onSubmit != null) {
                onSubmit(values);
            }
        },
    });
    useEffect(() => {
        setRelocationRequestForm(formik)
        // eslint-disable-next-line
    }, [])

    return relocationRequestForm;
}

const ViewTypeButton = (props: { isFullList: boolean }) => {
    const theme = useTheme<Theme>()
    const {primary, secondary} = theme.palette;
    const context = useContext(RelocationRequestContext);

    const isActive = context != null && props.isFullList === !context.partialTable;
    const backgroundColor = (isActive ? secondary.light : primary.light);

    const handleOnClick = () => context != null && context.count > 0 && context.setPartialTable(!context.partialTable)

    return (
        <div style={{
            backgroundColor: backgroundColor,
            borderRadius: "40px",
            height: "24px",
            marginLeft: "10px",
            width: "40px",
            objectFit: "cover"
        }}>
            <ActionImg icon={`/icons/${props.isFullList ? 'full' : 'half'}-list.svg`}
                       iconStyle={{height: "26px"}} onClick={() => handleOnClick()}/>
        </div>
    )
}

const searchBarStyles = makeStyles(() => ({
    textField: {
        width: "240px"
    }
}))

type SearchBarProps = {
    loading?: boolean
}

const SearchBar = ({loading}: SearchBarProps) => {
    const context = useContext(RelocationRequestContext);
    const {t} = useTranslation('relocationRequests');
    const classes = searchBarStyles()
    return (
        <form onSubmit={context?.form?.handleSubmit}>
            <Box
                sx={{
                    display: "flex",
                    padding: "1rem",
                    paddingBottom: "77px",
                    paddingLeft: '5px',
                    justifyContent: "left",
                    alignItems: "end"
                }}>
                <Box
                    sx={{
                        display: "flex",
                        width: "100%"
                    }}>
                    <TextField id="requestId" label={t('list.search.searchRequestId')}
                               className={classes.textField}
                               defaultValue={context?.form?.initialValues.requestId}
                               onChange={context?.form?.handleChange}/>
                    <TextField id="listQuery" label={t('list.search.searchTerm')}
                               className={classes.textField}
                               defaultValue={context?.form?.initialValues.listQuery}
                               onChange={context?.form?.handleChange}/>
                    <Button
                        disabled={loading}
                        sx={{minWidth: "40px", padding: "0px", margin: "10px"}}
                        type={'submit'}
                        icon={loading === true ?
                            <CircularProgress size={20} color='inherit' /> :
                            <SearchIcon
                            sx={{fontSize: '18px', width: "unset", height: "80%", transform: "scale(-1,1)"}}/>}/>
                    <Box sx={{flexGrow: 1, display: "flex", justifyContent: "end", alignItems: 'center'}}>
                        <ViewTypeButton isFullList={true}/>
                        <ViewTypeButton isFullList={false}/>
                    </Box>
                </Box>
            </Box>
        </form>
    )
}

const relocationRequestStyles = makeStyles(() => ({
    relocationRequestsPage: {
        backgroundColor: "whitesmoke",
        display: "block",
        padding: "0 1rem",
    },
    relocationRequestsTableAndDetails: {
        display: "flex"
    }
}))

const RelocationRequests: FC<{}> = (): ReactElement => {
    const classes = relocationRequestStyles()
    const {
        selectedPartner,
        setSelectedPartner,
        tableProps,
        count,
        pageCount,
        page,
        filters,
        loadNextPage,
        changeQuery,
        partialTable,
        setPartialTable,
        loading,
        alert,
        relocationRequestDetail,
        relocationRequestDetailLoading,
        onAnonymizeRelocationRequest,
        anonymizationConfirmationModal,
        isAnonymizingRelocationRequest,
        leads,
    } = useRelocationRequestsLoader(true);

    const handleOnRowClick = (data: RelocationRequest) => setSelectedPartner(data);
    const isRowActive = (data: RelocationRequest) => data.requestId === selectedPartner?.requestId;

    const handleOnSubmit = (values: RelocationRequestFilters) => changeQuery(values)

    const form = useRelocationRequestFormik(handleOnSubmit);
    const isAnyPageLoaded = (): boolean => page > -1;

    const ctx = {
        partialTable,
        setPartialTable,
        count,
        form,
        alert,
        relocationRequestDetail,
        relocationRequestDetailLoading,
        onAnonymizeRelocationRequest,
        anonymizationConfirmationModal,
        isAnonymizingRelocationRequest,
        leads,
    }

    return (
        <RelocationRequestContext.Provider value={ctx}>
            <Box className={classes.relocationRequestsPage}>
                {form && <SearchBar loading={loading}/>}
                <Box className={classes.relocationRequestsTableAndDetails}>
                    {isAnyPageLoaded() ?
                        <>
                            <Box sx={{width: (selectedPartner && partialTable) ? 'max-content' : '100%'}}>
                                <UmzugEasyTable  {...tableProps}
                                                 onRowClick={(data) => handleOnRowClick(data)}
                                                 isRowActive={(data) => isRowActive(data)}
                                                 isHeaderSticky={true}
                                                 onLastRowRendered={() => loadNextPageIfPossible(page, pageCount, loadNextPage)}
                                                 height={'calc(100vh - 280px)'}
                                />
                                <UmzugEasyTableFooter
                                    count={count} search={filters?.requestId || filters?.listQuery}
                                    translateFile="relocationRequests"
                                    translation={{
                                        withoutSearch: 'list.requestsFound',
                                        withSearch: 'list.filteredRequestsFound'
                                    }}
                                />
                            </Box>
                            { (selectedPartner && partialTable) &&
                                <RelocationRequestDetail />
                            }
                        </>
                        :
                        <MultipleRowSkeleton rows={15} fontSize='30px' styles={{ width: '100%' }} />
                    }
                </Box>
            </Box>
        </RelocationRequestContext.Provider>
    );
};

export default RelocationRequests;
