import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import {PartnerFilters} from "./partner/PartnerFilters";
import {ContractFilters} from './contract/ContractFilters';

import {
    AuthenticationResult,
    InteractionRequiredAuthError,
    IPublicClientApplication,
    SilentRequest,
} from "@azure/msal-browser";
import {getMsalInstance} from "../authConfig";

export const acquireAccessToken = async (
    msalInstance: IPublicClientApplication
): Promise<string> => {
    const account = msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0];

    if (!account) {
        await msalInstance.loginRedirect();
        return Promise.reject(new Error("Redirecting for login"));
    }

    const request: SilentRequest = {
        scopes: [process.env.REACT_APP_AAD_BACKEND_SCOPE as string],
        account,
    };

    try {
        const authResult: AuthenticationResult = await msalInstance.acquireTokenSilent(request);
        return authResult.accessToken;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            await msalInstance.acquireTokenRedirect(request);
            return Promise.reject(new Error("Redirecting for authentication"));
        } else {
            throw error;
        }
    }
};

export function getApiClient(): Promise<AxiosInstance> {
    const axiosInstance = axios.create();

    return getMsalInstance()
        .then((instance: IPublicClientApplication) => {
            return acquireAccessToken(instance)
                .then((token: string) => {
                    axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
                        return {
                            ...config,
                            headers: {
                                ...config.headers,
                                "Authorization": `Bearer ${token}`
                            }
                        };
                    });
                    return axiosInstance;
                });
    })
}

export function mapFilters(filter: PartnerFilters | ContractFilters): string { // add new type | NewType when you add new filter
    if (filter == null) {
        return '';
    }
    const filterAsAny = filter as any;
    return Object.keys(filterAsAny)
        .filter((key: string) => filterAsAny[key] != null)
        .filter((key: string) => filterAsAny[key].toString().trim().length > 0)
        .map(key => `${key}=${filterAsAny[key].toString()}`)
        .join("&")
}

export const apiVersion = '/api/v1'